import React from 'react';
import { Table } from 'react-bootstrap';

const CourseFeeTable = ({ handleCourseModal, ClassesAndFees }) => {
    return (
        <section className="courseFeeTableWrapper">
            <div className="container">
                <Table className="table-responsive courseFeeTable">
                    <thead>
                        <tr>
                            <th>Sl.No</th>
                            <th>Module</th>
                            <th>Fee</th>
                            <th>Enroll Now</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ClassesAndFees.map((item, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.module}</td>
                                    <td>{item.fee}</td>
                                    <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </section>
    )
}

export default CourseFeeTable;