import React from 'react'
import { Modal, Button } from 'react-bootstrap'

function PreviewCertificateModal(props) {

    const handleClose = () => {
        props.setShowCertificate((prevState) => {
            return { ...prevState, show: false };
          });
    }

    return (
            <Modal id="previewCertificate" show={true} onHide={handleClose} animation={true} aria-labelledby="contained-modal-title-vcenter"
            centered>
                <Button onClick={handleClose}><span aria-hidden="true">×</span></Button>
                <Modal.Body>
                    <img src={props.certificateURL} alt="Crampete Certificate" />
                </Modal.Body>
            </Modal>
    );
}

export default PreviewCertificateModal;