import React from "react";
import { Table, Button } from "react-bootstrap"

const WebDesignChennai = ({ handleCourseModal }) => {
    return (
        <section className="webDesignWrapper">
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2><strong>Bonus: Video Course</strong></h2>
                            <p>If you sign up for our classroom program today, you'll get a year of unrestricted access to our Web Design Self-Paced Video Course. Even if you skip one or two classes, the lessons will still be available in this video course.Hurry! This is offered only for a limited one.</p>
                            <div className="my-3">
                                <Button className="btn-enroll" onClick={handleCourseModal}>Enroll Now</Button>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/web-design-chennai/web-design-course-in-chennai.png" alt="web design course in chennai" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="highlightWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/web-design-chennai/web-design-syllabus-in-chennai.png" alt="web design syllabus in chennai" />
                        </div>
                        <div className="col-md-6">
                            <h2><strong>Syllabus of our web design training in Chennai</strong></h2>
                            <p>It's never too late to learn a new skill. Learn core concepts of
                            web design course· Get familiar with the latest syllabus which is designed to teach students of all aspects of web design through
                            a series of mini-projects and one capstone projects.This ‘Web
                            Design Cramdegree” will train you professionally.The syllabus for Crampete's Web Design course in Chennai has been carefully
                            crafted by experienced industry experts to cover beginner to advanced concepts in web designs through hands-on training.
                            We ensure individual’s attention and teach them with the latest equipment and software versions. We have been ranked as No 1 training institute in Chennai by leading web designers from the industry.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-center">
                <div className="container">
                    <p>This course will teach you the most commonly used technologies in web design. Examine the most popular programming languages, frameworks, and tools that top recruiters seek out. This certification will make the student get the leading job posts in the MNC. The aspirants who are looking to kick start their career can take up this web design course in Chennai at our institute which leads to a successful path to their career.</p>
                </div>
            </section>
            <section className="highlightWrapper">
                <div className="container">
                    <h2 className="text-center"><strong>Web Design course with placement in Chennai</strong></h2>
                    <p className="text-center">We are one of the renowned institutes which provides job-oriented Web Design courses in Chennai with placement guarantee. Almost all of our students get placed in leading MNC within 30 days of course completion. Our students could able to achieve this because of the following reasons provided by us</p>
                    <div className="row text-center pt-5">
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/web-design-chennai/downloadable-code.png" alt="downloadable code" />
                                <p className="courseText">17 hands-on lab sessions with downloadable code (for practice)</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/web-design-chennai/mini-projects.png" alt="mini projects" />
                                <p className="courseText">5 mini-projects to showcase in the portfolio</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/web-design-chennai/web-design-skills.png" alt="web-design-skills" />
                                <p className="courseText">A comprehensive Capstone project to demonstrate a wide range of web design skills</p>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center courseWrapper">
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/web-design-chennai/interview-job-reference.png" alt="interview job reference" />
                                <p className="courseText">Job reference and interview scheduling</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/web-design-chennai/mock-interview.png" alt="mock interview" />
                                <p className="courseText">Mock interviews (including soft skills)</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/web-design-chennai/professional-resume.png" alt="professional resume" />
                                <p className="courseText">Very attractive and professional resume</p>
                            </div>
                        </div>
                    </div>
                    <p className="text-center">We are also a MNC with offices in Singapore, Malaysia, etc. We have tie-ups with other MNCs and startups to cater your placements. So, if you are looking for a highly reputed, reliable, software development company that provides hands-on Web Design training with placement in Chennai, placements anywhere in India then you are in the right place. Fill up the form above to schedule a call back from us. Our hiring partners are HP, TATA, FORD, HEXAWARE and NTT with the highest pay.</p>
                </div>
            </section>
            <section className="text-center">
                <div className="container">
                    <p>
                    <strong>Note:</strong><br />
                    Also read about our Web Design course with placement in India( Web Design course with placement Bangalore, Web Design course with placement in Pune, Web Design course with placement in Hyderabad here).
                     </p>
                </div>
            </section>
            <section className="courseFeeWrapper">
                <div className="container text-center">
                    <h2><strong>Web design course fee in Chennai</strong></h2>
                    <p className="py-4">When it comes to fee structure, "Crampete's web design course fee is   Rupees. 25,000 Rupees," regardless of which training provider
              you select for your classroom training in web design. (Only thirty-five thousand dollars) The course fees for various web design training modules are listed below.</p>
                </div>
            </section>
            <section className="courseFeeTableWrapper">
                <div className="container">
                    <Table className="table-responsive courseFeeTable">
                        <thead>
                            <tr>
                                <th>Sl.No</th>
                                <th>Module</th>
                                <th>Fee</th>
                                <th>Enroll Now</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>HTML</td>
                                <td>Rs, 5000</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>CSS</td>
                                <td>Rs, 5000</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>PHP</td>
                                <td>Rs, 5000</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Object Modelling</td>
                                <td>Rs, 5000</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Java Script</td>
                                <td>Rs, 5000</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>16 Hands-on lab + downloadable course</td>
                                <td>FREE</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>5 Mini Projects</td>
                                <td>FREE</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>One Capstone Project</td>
                                <td>FREE</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Videos and training materials of our 5 stars rated self-paced web design course for One year</td>
                                <td>FREE</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Web design course Download</td>
                                <td>FREE</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>Mock Interview, Portfolio building, Placement assistance</td>
                                <td>FREE</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>Course Certification</td>
                                <td>FREE</td>
                                <td><button onClick={handleCourseModal}>CHECK AVAILABILITY</button></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </section>
            <section className="highlightWrapper">
                <div className="container text-center">
                    <p>
                        You have the choice of paying the web design course fees in one lump sum and receiving a discount, or paying in three to six instalments through our financing partner's no cost EMI options. In addition, you can refer your friends and receive Rupees (Rs. 1000) for each referral. Many prestigious institutes in Chennai, such as IIT and NIIT, offer web design training in a versatile learning environment for a high price. We would choose to go for a low cost and a short period of time in order to encourage students to learn and acquire more experience while also allowing them to rapidly enter the workforce.
                    </p>
                </div>
            </section>
            <section>
                <div className="container">
                    <h2 className="text-center">
                        <strong>Web Design Training in Chennai - Trainer Profile - Highlights</strong>
                    </h2>
                    <div className="row py-5">
                        <div className="col-md-6">
                            <p>
                                Crampete is regarded as one of the best web designing training institutes in Chennai due to its Syllabus, Course Curriculum,
                                Trainer Content & Experience, Hands-on training, mini-projects, Capstone Project, and other features.
                            </p>
                            <p>
                                Both of our instructors have a thorough understanding of the
                                subjects they teach. They are real experts in their respective
                                fields. They are accredited experts in their fields and work for
                                top multinational corporations. As a result, they have a lot of
                                live ventures, which the trainers can use during the sessions.
                                The teacher profile for our web design classroom course can
                                be found here.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/web-design-chennai/web-design-trainer-in-chennai.png" alt="web design trainer in chennai" />
                        </div>
                    </div>
                    <ul className="py-4 bookTypeList">
                        <li>Over 17+ years of industry experience.</li>
                        <li>Full expertise in machine learning.</li>
                        <li>Possess excellent skills in all aspects.</li>
                        <li>Trainers train the students with the recent algorithms and tools that are used in web design.</li>
                        <li>Trainers guide the students with necessary interview tips & supports in resume building.</li>
                        <li>Completed over 30+ projects.</li>
                        <li>On-site and pre-sales experience.</li>
                        <li>Passionate about training.</li>
                        <li>Tutors guide the students to enhance their technical skills in web design.</li>
                        <li>Mentored over 500+ students.</li>
                    </ul>
                </div>
            </section>
            <section className="courseFeeWrapper">
                <div className="container text-center">
                    <p>
                        Before enrolling in the course, sign up for a web design demo class in Chennai and speak with our trainer for a career counselling
                        session and to clear any questions you may have.
                    </p>
                    <div className="signEnrollWrapper pt-3">
                        <Button className="signup__btn" onClick={handleCourseModal}>Sign Up</Button>
                        <Button className="signup__enroll" onClick={handleCourseModal}>Enroll Now</Button>
                    </div>
                </div>
            </section>
            <section className="highlightWrapper">
                <div className="container">
                    <h2 className="text-center"><strong>Web Design Training in Chennai - Batches, Duration, and Schedules</strong></h2>
                    <p className="text-center py-3">
                        We provide various training batches and schedules in order to provide opportunities to different types of students, such as freshmen, career seekers, seasoned professionals, freelancers, and so on. You can enrol in any of the programmes.
                    </p>
                    <ul className="py-4 bookTypeList">
                        <li>Week Days Morning batch</li>
                        <li>Week Days Evening batch</li>
                        <li>Week Days Regular batch</li>
                        <li>Week End batch</li>
                        <li>Online Instructor lead batch</li>
                        <li>Online Self paced batch.</li>
                    </ul>
                    <p>
                        Aside from batches, the location and length of the course will remain consistent. "In Chennai, the Web Design course lasts 100 hours."
                        If you're working in Chennai or elsewhere in Tamilnadu and want to take a web design course close to home, you can take a demo class from us. T-Nagar, the best training institute, is where we are based. We offer certification in web design as well as job placement assistance with top companies. Additionally, we give a self-paced web design online course.So, if the training mode isn't an issue, you should look into that course. This is considered India's best web design course]
                    </p>
                </div>
            </section>
            <section class="webChennaiLiveAreas">
                <div class="container">
                    <h3>LIVE NEAR THESE AREAS?</h3>
                    <p>Kodambakkam, Nungambakkam, Saidapet, Guindy, Ashok Nagar, Vadapalani, MMDA, West Mambalam, Valluvar Kottam, Royapettah, Teynampet, Nandanam, Triplicane, Alwarpet</p>
                    <p>Come to our Crampete Learning Centre in Mount Road or T Nagar! we're placed in the middle and are quite accessible for most places in Chennai.We're worth the extra drive as we bring you the best courses at the best full stack developer course in Chennai!</p>
                </div>
            </section>
        </section>
    )
}

export default WebDesignChennai;