import React, { useState, useEffect } from "react";
import { Button, Tabs, Tab } from "react-bootstrap"
import CourseFeeTable from "./course-fee-table";

const ClassesAndFees = [
    {
        "module": "Python for data analysis",
        "fee": "Rs, 5000"
    },
    {
        "module": "Intro to Statistics",
        "fee": "Rs, 5000"
    },
    {
        "module": "Inferential Statistics",
        "fee": "Rs, 5000"
    },
    {
        "module": "Regression and Anova",
        "fee": "Rs, 5000"
    },
    {
        "module": "Exploratory data analysis and supervised machine learning",
        "fee": "Rs, 5000"
    },
    {
        "module": "16 Hands-on lab + downloadable course",
        "fee": "FREE"
    },
    {
        "module": "5 Mini Projects",
        "fee": "FREE"
    },
    {
        "module": "One Capstone Project",
        "fee": "FREE"
    },
    {
        "module": "Videos and training materials of our 5 stars rated self-paced Data Science course for One year",
        "fee": "FREE"
    },
    {
        "module": "Data Science course Download",
        "fee": "FREE"
    },
    {
        "module": "Mock Interview, Portfolio building, Placement assistance",
        "fee": "FREE"
    },
    {
        "module": "Course Certification",
        "fee": "Rs, 5000"
    }
]

const useAudio = () => {
    const [audio] = useState(typeof Audio !== "undefined" && new Audio('https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/audio/data-science-course-in-chennai-with-placement-audio.mp3'));
    const [playing, setPlaying] = useState(false);
  
    const toggle = () => {setPlaying(!playing)};
  
    useEffect(() => {
        playing ? audio.play() : audio.pause();
      },
      [playing]
    );
  
    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
      };
    }, []);
  
    return [playing, toggle];
  };

const DataScience = ({ handleCourseModal, url }) => {
    const [isAdditionalInfo, setIsAdditionalInfo] = useState(false);
    const [playing, toggle] = useAudio(url);

    return (
        <section className="webDesignWrapper">
            <section>
                <div className="container">
                    <h2><strong>Companies that hire Data Scientists</strong></h2>
                    <div className="row pt-4">
                        <div className="col-md-3 dataScientistsWrapper">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/accenture.png" alt="accenture" />
                        </div>
                        <div className="col-md-3 dataScientistsWrapper">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/capgemini.png" alt="capgemini" />
                        </div>
                        <div className="col-md-3 dataScientistsWrapper">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/linkedin.png" alt="linkedin" />
                        </div>
                        <div className="col-md-3 dataScientistsWrapper">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/zoho.png" alt="zoho" />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2><strong>Offered Video Course</strong></h2>
                            <p>Register with us today to get one year of unlimited access to our Data Science Self-Paced Video Course. Avoid worrying about missing out classes, you can learn from this video course. Hurry! his is offered only for a limited one.</p>
                            <div className="my-3">
                                <Button className="btn-enroll" onClick={handleCourseModal}>Enroll Now</Button>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/data-science-course-in-chennai.png" alt="data science course in chennai" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="highlightWrapper">
                <div className="container">
                    <h2 className="text-center pb-4"><strong>Advantages</strong></h2>
                    <div class="dataScienceCourseText">
                        <ul>
                            <li>This video course contains a total of 700+ videos for 80 hours</li>
                            <li>Play it at 1.25x, 2x, speeds</li>
                            <li>Access this course on mobile, desktops, laptops and tablets</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="text-center">
                <div className="container">
                    <h2><strong>Key features of Data Science course training in Chennai</strong></h2>
                    <div className="dataScience__keysWrapper pt-5">
                        <div>
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/100-hours-of-video.png" alt="100 hours of video" />
                            <p>100 hours of video</p>
                        </div>
                        <div>
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/affordability.png" alt="affordability" />
                            <p>Affordability</p>
                        </div>
                        <div>
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/experienced-trainers.png" alt="experienced-trainers" />
                            <p>Experienced Trainers</p>
                        </div>
                        <div>
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/weekend-classes.png" alt="weekend classes" />
                            <p>Weekend Classes</p>
                        </div>
                        <div>
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/flexible-timings.png" alt="flexible timings" />
                            <p>Flexible Timings</p>
                        </div>
                        <div>
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/placement-support.png" alt="placement support" />
                            <p>Placement Support</p>
                        </div>
                        <div>
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/certification.png" alt="certification" />
                            <p>Certification</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="highlightWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2><strong>Syllabus of our Data Science Course Training in Chennai</strong></h2>
                            <p>
                                Learn Data Science Course in Chennai at Crampete. We are on
                                the right track for any aspirant to become an expert in the field
                                of Data Science. Designed by our faculty data scientists with
                                immense experience in the industry, the course combines sound theory coupled with real-time projects in the subject.
                           </p>
                            <p>
                                Our 100 hour Data Science Course training in Chennai syllabus
                                is designed in such a way that it makes college students, graduates and other professional degree holders from other colleges learn
                                all aspects through a couple of mini-projects. This " Data Science Cramdegree" will make you a professional. The course has been carefully designed and taught by industry experts with ample experience in the field to cover beginner-level concepts to advanced concepts through hands-on training. We ensure individual’s attention and teach them with the latest equipment and software versions.
                                We have been ranking as No 1 training institute in Chennai by
                                leading Data Scientists from the industry.
                           </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/data-science-course-syllabus-in-chennai.png" alt="data science course syllabus in chennai" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="dataScience__tabWrapper">
                <Tabs defaultActiveKey="data-science-course-chennai" id="data-science-chennai-tabs">
                    <Tab eventKey="data-science-course-chennai" title="Once you complete our Data Science Course training in Chennai, you should be able to...">
                        <div className="container">
                            <h4 className="tab__headerText">Once you complete our Data Science Course training in Chennai, you should be able to...</h4>
                            <ul className="dataScience__tabList">
                                <li>Enhance knowledge and proficiency in machine learning algorithms</li>
                                <li>Can learn artificial intelligence easily</li>
                                <li>Become professionals with data management technologies</li>
                                <li>Expertise in data analytics</li>
                            </ul>
                        </div>
                    </Tab>
                    <Tab eventKey="learn-from-syllabus" title="Some of the major things you’ll learn from the syllabus are...">
                        <div className="container">
                            <h4 className="tab__headerText">Some of the major things you’ll learn from the syllabus are...</h4>
                            <ul className="dataScience__tabList">
                                <li>Introduction to statistical concepts that are used in data science.</li>
                                <li>Learn more about three most popular python libraries</li>
                                <li>Learn formal modelling or hypothesis testing.</li>
                                <li>Analysis of a representative sample of large data sets with the help of inferential statistics.</li>
                                <li>Learn about the analysis of variance (ANOVA)</li>
                                <li>Expertise in Machine learning algorithms.</li>
                            </ul>
                        </div>
                    </Tab>
                </Tabs>
                <div className="container pb-5">
                    <p style={{ color: "#707070", textAlign: "center" }}>
                        Data Science course certification is the professional qualification that shows the ability of the candidate to attain complete subject knowledge and learn all the basic tools and algorithms used in Data Science. This certification will make the student get the leading job posts in the MNC. The aspirants who are looking to kick start their career can take up this Data science course in Chennai at our institute which leads to a successful path to their career.
                        </p>
                </div>
            </section>

            <section className="highlightWrapper">
                <div className="container">
                    <h2 className="text-center"><strong>Data Science Course with placement in Chennai</strong></h2>
                    <p className="text-center">We are one of the renowned institutes which provides job-oriented Data Science courses in Chennai with placement guarantee. Almost all of our students get placed in leading MNC within 30 days of course completion. Our students could able to achieve this because of the following reasons provided by us</p>
                    <div className="row text-center pt-5">
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/downloaded-code.png" alt="downloadable code" />
                                <p className="courseText">17 hands-on lab sessions with downloadable code (for practice)</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/mini-projects.png" alt="mini projects" />
                                <p className="courseText">5 mini-projects to showcase in the portfolio</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/data-science-skills.png" alt="data science skills" />
                                <p className="courseText">A comprehensive Capstone project to demonstrate a wide range of web design skills</p>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center courseWrapper">
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/job-reference-interview-schedule.png" alt="job reference interview schedule" />
                                <p className="courseText">Job reference and interview scheduling</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/mock-interview.png" alt="mock interview" />
                                <p className="courseText">Mock interviews (including soft skills)</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="webDesignTextWrapper">
                                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/professional-resume.png" alt="professional resume" />
                                <p className="courseText">Very attractive and professional resume</p>
                            </div>
                        </div>
                    </div>
                    <p className="text-center">we are also a MNC with offices in Singapore, Malaysia, etc. We have tie-ups with other MNCs and startups to cater your placements. So, if you are looking for a highly reputed, reliable, software development company that provides hands-on Data Science training with placement in Chennai, placements anywhere in India then you are in the right place. Fill up the form above to schedule a call back from us. Our hiring partners are HP, TATA, FORD, HEXAWARE and NTT with the highest pay.</p>
                </div>
            </section>
            <section className="container text-center">
                <strong>Note:</strong><br />
                <p>Also read about our Data Science course with placement in India( Data Science course with placement Bangalore, Data Science course with placement in Pune, Data Science course with placement in Hyderabad here).</p>
            </section>
            <section className="courseFeeWrapper">
                <div className="container text-center">
                    <h2><strong>Data Science course fee in Chennai</strong></h2>
                    <p className="py-4">On coming to fee structure, irrespective of any training provider that you choose for your classroom training for Data Science, "Crampete's Data Science course fee is Rupees. 30,000 Rupees".(Thirty Thousand Only) Here is the course fee split up of various Data Science training modules.</p>
                </div>
            </section>
            <CourseFeeTable handleCourseModal={handleCourseModal} ClassesAndFees={ClassesAndFees} />
            <section className="highlightWrapper">
                <div className="container text-center">
                    <p>
                        You can either pay the Data Science course fees in a single payment and get a discount or you can avail 3 to 6 installments through our financing partner no cost EMI options. Besides, you can refer to your friends and get Rupees. 1000 cashback for each referral. Many renowned institutes in Chennai like IIT, NIIT are offering Data Science training in a flexible learning way with a high amount of fees. We would prefer to go for an affordable cost with a short duration of time in order to make students learn and gain better knowledge and
                        get into jobs quickly.
                    </p>
                </div>
            </section>
            <section>
                <div className="container">
                    <h2 className="text-center">
                        <strong>Data Science Training in Chennai - Trainer Profile - Highlights</strong>
                    </h2>
                    <div className="row py-5">
                        <div className="col-md-6">
                            <p>
                                Crampete is rated as one of the best Data Science training institute in Chennai for the Syllabus, Course curriculum, Trainer quality & expertise, Hands-on training, mini-projects, Capstone Project.
                            </p>
                            <p>
                                All our trainers possess in depth practical understanding on the subject they teach. They are true blue experts in their field of study. They are certified professionals in their respective domains as well as working in Top MNC’s  So they have many live projects, trainers will use these projects during the sessions. Here is our Data Science classroom course trainer profile.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/data-science-training-in-chennai.png" alt="data science training in chennai" />
                        </div>
                    </div>
                    <ul className="py-4 bookTypeList">
                        <li>Over 17+ years of industry experience.</li>
                        <li>Full expertise in machine learning.</li>
                        <li>Possess excellent skills in all aspects.</li>
                        <li>Trainers train the students with the recent algorithms and tools that are used in data science.</li>
                        <li>Trainers guide the students with necessary interview tips & supports in resume building.</li>
                        <li>Completed over 30+ projects.</li>
                        <li>On-site and pre-sales experience.</li>
                        <li>Passionate about training.</li>
                        <li>Tutors guide the students to enhance their technical skills in Data Science.</li>
                        <li>Mentored over 500+ students.</li>
                    </ul>
                </div>
            </section>
            <section className="courseFeeWrapper">
                <div className="container text-center">
                    <p>
                        Register now for a Data Science demo class in Chennai and talk to our trainer for a career counseling session and clear all your doubts before enrolling for the course.
                    </p>
                    <div className="signEnrollWrapper pt-3">
                        <Button className="signup__btn" onClick={handleCourseModal}>Sign Up</Button>
                        <Button className="signup__enroll" onClick={handleCourseModal}>Enquire Now</Button>
                    </div>
                </div>
            </section>
            <section className="highlightWrapper">
                <div className="container">
                    <h2 className="text-center"><strong>Data Science Training in Chennai - Batches, Duration, and Schedules</strong></h2>
                    <p className="text-center py-3">
                        Inorder to give oppurtunities to  different categories of students like freshers, job seekers, experienced professionals, freelancers, etc, we provide different training batches and schedules. You can register in any one of the
                    </p>
                    <ul className="py-4 bookTypeList">
                        <li>Week Days Morning batch</li>
                        <li>Week Days Evening batch</li>
                        <li>Week Days Regular batch</li>
                        <li>Week End batch</li>
                        <li>Online Instructor lead batch</li>
                        <li>Online Self paced batch.</li>
                    </ul>
                    <p className="text-center">
                        Other than batches, the venue  and duration of the course will always be the same. "The Data Science course duration in Chennai is 100 hours".
                    </p>
                </div>
            </section>
            <section class="webChennaiLiveAreas">
                <div class="container text-center">
                    <p>If you’re placed at Chennai or any in Tamilnadu and thinking to join a Data Science developer course near to your residence, then you should take a demo class from us. We are located in T-Nagar, the best training institute .We provide data science course certification with placement assistance with top firms. Besides, we also have a self-paced Data Science online course. So, if the mode of training is not a problem you can take a look at that course. This is rated as the best Data Science course in India.</p>
                </div>
            </section>
            <section className="highlightWrapper">
                <div className="container text-center">
                    <h2><strong>Play the audio overview of the Data Science course</strong></h2>
                </div>
                <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/data-science-course-audio.png" alt="data science course audio" onClick={toggle} style= {{cursor : 'pointer'}}/>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-6">
                            <h2><strong>Data Science certification in Chennai</strong></h2>
                            <p className="pt-3">
                                The entire Data Science course curriculum was developed by industry experts to help you land the best jobs at top multinational corporations. You'll be working on a range of projects and tasks as part of your Data Science online classes, many of which have significant consequences in real-world scenarios. They'll make it easier for you to succeed in your career.
                            </p>
                            <p>
                                There will be quizzes at the end of this Data Scientist course in Chennai that accurately represent the types of questions asked in the respective certification exams. They will assist you in improving your grades.
                            </p>
                            <p>
                                When you finish the project work, you will receive a Crampete course completion certificate. The top 80+ MNCs, including Ericsson, Cisco, Cognizant, Sony, Mu Sigma, Standard Chartered, TCS, Genpact, and others, accept this Data Science credential.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/data-science-certification-in-chennai.png" alt="data science certification in chennai" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/data-science-chennai/data-science-salary-in-chennai.png" alt="data science salary in chennai" />
                        </div>
                        <div className="col-md-6">
                            <h2><strong>Data science salary in Chennai</strong></h2>
                            <p className="pt-3">
                                The average salary for a Data Scientist in Chennai, India is Rupees 630,800. Data scientists have been in demand for a while, and the demand has not reduced. This is in part due to the fact that the supply was never met with, in the first place! Taking this account, it is easy to see why the starting salary for even a fresher in the data science field is quite high when compared to other fields.
                            </p>
                            <p>
                                An entry-level data science professional should expect to earn about INR 570,440 per year, which is a decent salary! They will work for a yearly package of about INR 730,450 with around 5 years of experience under their belt. A data scientist receives an additional INR 120,230 to INR 160,240 for each year they work beyond this stage.
                            </p>
                            <p>
                                As we move beyond this stage, the earning capacity increases to INR 20 lacs per year. Obviously, there is a lot of money to be made in this sector!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <p style={{ color: "#3A95F5", textAlign: "center" }} >Additional Information of Data Science Training in Chennai</p>
                    <h2 className="text-center"><strong>How to become a Data scientist?</strong></h2>
                    <ul style={{ listStyleType: "decimal", marginLeft: '20px' }}>
                        <li>A bachelor's degree in information technology, computer science, mathematics, or a related field is required.</li>
                        <li>A master's degree in Data Science or a closely related field is needed.</li>
                        <li>Job experience in the sector you want to work in is important (physics, healthcare, pharma, etc.)</li>
                    </ul>
                </div>
            </section>
            {isAdditionalInfo ?
                <>
                    <section className="highlightWrapper">
                        <div className="container">
                            <h2 className="text-center"><strong>Knowledge on languages required</strong></h2>
                            <ul className="dataScience__tabList">
                                <li>Statistics, algebra, and machine learning are all skills that are useful to have. </li>
                                <li>Hadoop, Spark, and MapReduce are examples of tools. Combine these with Tableau, a data visualisation and reporting application.</li>
                                <li>R or Python coding.</li>
                                <li>Ability to research databases, including data forms, storage systems, and retrieval methods.</li>
                            </ul>
                        </div>
                    </section>
                    <section>
                        <div className="container text-center">
                            <h2><strong>What is Data Science?</strong></h2>
                            <p>
                                This is the first stop on the Data Science road. It will assist you in getting started with the curriculum, scheduling your classes, and connecting with other students and teaching assistants. You'll learn how to work with and explore data using a range of visualisation, analytical, and statistical techniques along the way.
                    </p>
                        </div>
                    </section>
                    <section className="highlightWrapper">
                        <div className="container text-center">
                            <h2 className="pb-3" ><strong>Career path for Data scientist</strong></h2>
                            <p>
                                Those who complete a Data Science course successfully will pursue a career as a Data Scientist. The average salary in this city is about 9.33 lacs. After that, being a Senior Data Scientist is the next step to take. The annual package here rises to about 16 lacs. After that, one should work steadily and methodically toward being a Lead Data Scientist. This is the most lucrative choice, paying up to 35L a year or more!
                    </p>
                            <p>
                                Candidates can learn a variety of skills, like Statistical Analysis, Predictive Analysis (using Python), Big Data Analytics, Data Visualization, Machine Learning, and more, as they progress through the course.These skills, which are sufficiently stressed in each section of the programme, can prepare candidates for a wide range of activities, including working with statistical packages such as numpy, scipy, scikit, and others, as well as devising ML / Deep Learning experiments focused on statistical analyses.
                    </p>
                            <p>
                                It goes without saying that the candidates would be expected to have a clear understanding of statistics, linear algebra, and calculus. As they move through the programme, they will be able to easily switch between a number of problem statements that span Computer Vision and Natural Language Processing. As a result, they will have a plethora of opportunities to be a part of today's dynamic industry.
                    </p>
                        </div>
                    </section>
                    <section>
                        <div className="container text-center">
                            <h2><strong>What are the skills required?</strong></h2>
                            <p>
                                A data scientist should be specialised in a number of fields, including mathematics, statistics, and Big Data.
                            </p>
                            <p>
                                To understand trends in these results, data scientists should have a deeper understanding of mathematics and statistics, as wellas strong analytical skills.
                            </p>
                            <p>
                                We should be able to create machine learning algorithms that can perform predictive analysis and generate sample data from given information.
                            </p>
                        </div>
                    </section>
                    <section className="dataScience__tabWrapper">
                        <Tabs defaultActiveKey="data-science-analyst" id="data-science-chennai-tabs">
                            <Tab eventKey="data-science-analyst" title="What is Data scientist VS Data analyst?">
                                <div className="container">
                                    <h4 className="tab__headerText pb-3 text-center">What is Data scientist VS Data analyst?</h4>
                                    <div className="text-center">
                                        <p>In contrast to data analysts, data scientists work at a far higher level.</p>
                                        <p>Data analysts simply analyse and query data using basic tools such as SQL and Excel, and then offer solutions to the business issues they are faced with. Big data were dealt with by data scientists (Unstructured data).</p>
                                        <p>However, a data scientist develops business questions, analyses data patterns, and designs a mathematical model to address certain questions, as well as using visualisation tools to help others comprehend their findings. They help businesses overcome critical business challenges and make strategic decisions.</p>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="data-engineer-scientist" title="Should one be a Data Engineer or a Data scientist?">
                                <div className="container">
                                    <h4 className="tab__headerText pb-3 text-center">Should one be a Data Engineer or a Data scientist?</h4>
                                    <div className="text-center">
                                        <p>Data engineers generally come from a programming context, while data scientists are mathematically educated. Data engineers have 5 times the number of work openings as data scientists, according to Glassdoor.</p>
                                        <p>A data engineer is someone who designs, builds, and manages data. MongoDB, Redis, Hive, SAP, and other database resources are used by data engineers.</p>
                                        <p>A data scientist creates insights and seeks answers to business challenges. Tableau, SAS, SPSS, and Rapidminer are among the methods used by data scientists. Both of these positions make use of resources like Spark, scale, and others.</p>
                                        <p>Go for the Data Engineer role if you have a strong database experience and strong technical skills.</p>
                                        <p>You can develop a career as a Data Scientist if you have good analytical abilities, mathematical and statistical skills, as well as technical experience.</p>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </section>
                    <section>
                        <div className="container">
                            <div className="mb-5">
                                <h4 className="pb-2"><strong>Which institute is best for data science in Chennai?</strong></h4>
                                <p>Well there are many institutes to study Data Science but what mostly matters is whether the course is worth it or not. You need to understand that every aspirant is different from one another when they are compared based on their profiles, like some may be working professionals, some maybe really striving and aspiring to become Data Scientist and some may just be curious about the field and are thinking about the field. Like so, many other things differ from one aspirant to another.</p>
                            </div>
                            <div className="mb-5">
                                <h4 className="pb-2"><strong>How much does a data science course cost?</strong></h4>
                                <p>To become a data scientist few steps are required from basic knowledge of Statistics and then Data analysis Data Visualization and a bit of programming are part of becoming a successful data scientist and quick decision making also plays a vital role to it. This whole journey takes some time and expert guidance and at the end some live projects. The whole thing costs around more than one lakh probably. Many online courses are available to make the learning experience a bit convenient. Webdrills, Edx, Upgrad are online courses providers anyone can choose any site for learning but the person must be dedicated enough to grab the techniques easily.</p>
                            </div>
                            <div className="mb-5">
                                <h4 className="pb-2"><strong>Which certification is best for data science?</strong></h4>
                                <p>Well, Data Science has contoured the world all around, today we are all surrounded by new technological changes, and no wonder these dynamic and continuous technological changes have raised the pay scales for professionals in data science since organizations have realized the importance of it and predict a great impact in near future. Some best certifications are,</p>
                                <ul style={{ listStyleType: 'decimal' }}>
                                    <li>AI and ML certification course.</li>
                                    <li>Data science and AI certification course.</li>
                                    <li>Data science course for managers and leaders.</li>
                                </ul>
                            </div>
                            <div className="mb-5">
                                <h4 className="pb-2"><strong>What qualifications do I need to become a data scientist?</strong></h4>
                                <p>To become a data scientist, you could earn a Bachelor's degree in Computer science, Social sciences, Physical sciences, and Statistics. The most common fields of study are Mathematics and Statistics (32%), followed by Computer Science (19%) and Engineering (16%).
                                </p>
                            </div>
                            <div className="mb-5">
                                <h4 className="pb-2"><strong>What is a data science course?</strong></h4>
                                <p>In simple terms, data science is defined as the process of obtaining valuable insights from structured and unstructured data by using various tools and techniques. Some of the techniques practiced in data science include data extraction, data analysis, data mining, and data retrieval, to produce informative results. The person who is performing such kind of jobs is called a data scientist. Besides, it is widely used to make decisions and predictions through the use of prescriptive analytics, machine learning, and predictive causal analytics.
                                </p>
                            </div>
                            <div className="mb-5">
                                <h4 className="pb-2"><strong>Which is the best data science course in India?</strong></h4>
                                <ul style={{ listStyleType: 'decimal', paddingLeft: "20px" }}>
                                    <li>Data Science Specialization.</li>
                                    <li>Introduction to Data Science</li>
                                    <li>Applied Data Science with Python Specialization</li>
                                    <li>Data Science MicroMasters</li>
                                    <li>Dataquest</li>
                                    <li>Statistics and Data Science MicroMasters</li>
                                </ul>
                            </div>
                            <div className="mb-5">
                                <h4 className="pb-2"><strong>Is data science a good career?</strong></h4>
                                <p>
                                    Yes data science is a rising career if you look at a career in terms of job trends in relation to growth. Stats show that the US could potentially have as many as 250,000 open data science jobs by 2024 and that the data science skills gap will mean that companies are scrambling to train or hire talent in the coming years. The reasons why data science is such a fast growing job are due to the following reasons:
                                </p>
                                <p>
                                    The amount of data has skyrocketed. Due to the fact that the amount of data has grown exponentially, so has the need for people that can read and analyze it.
                                </p>
                            </div>
                            <div className="mb-5">
                                <h4 className="pb-2"><strong>Who can do a data scientist course?</strong></h4>
                                <p>
                                    Data scientists are highly educated – 88% have at least a Master's degree and 46% have PhDs – and while there are notable exceptions, a very strong educational background is usually required to develop the depth of knowledge necessary to be a data scientist Fresh college graduates who are curious to learn quantitative/statistical analysis, and have a fair knowledge of programming can pursue a career in Data Science. One doesn't have to necessarily possess a degree, or a Phd. to become a Data Science professional.
                                </p>
                            </div>
                            <div className="mb-5">
                                <h4 className="pb-2"><strong>What is the average salary for data scientists in India?</strong></h4>
                                <p>
                                    Data scientists are a type of analytical data experts who have the technical skills to solve complex problems along with the curiosity to explore what problems need to be solved. They must have both an analytical and business mindset coupled with technical aptitude.
                                </p>
                                <p>
                                    Data Scientists are currently among the highest paid professionals in the tech domain. Usually, the salary of Data Scientists depends on their skill set, work experience, job location, and the company they work for.
                                </p>
                                <p>
                                    According to glassdoor, the average annual pay of Data Scientists in India is Rs. 10,50,000 per annum. But one should not expect such a high salary at the very beginning. Entry-level and Junior Data Scientists usually earn around Rs. 3,80,000 - 5,00,000 per annum and mid-level Data Scientists earn anywhere between Rs. 5,00,000 - 8,00,000 per annum or more. The salary scale of senior-level Data Scientists having more than 15 years of experience can go as high as Rs. 20,00,000 - 40,00,000 per annum and more.
                                </p>
                            </div>
                            <div className="mb-5">
                                <h4 className="pb-2"><strong>What do you know about data science?</strong></h4>
                                <p>
                                    For the uninitiated, Data Science is the process of slicing through massive chunks of data, processing and analysing them for meaningful information that can help businesses get insights on concerns, customer experience, supply-chain and other prime aspects that would complement their business operations.
                                </p>
                            </div>
                        </div>
                    </section>
                </>
                : null}
            <div className="container mb-3">
                <div className="dataScience__readMore">
                    <button onClick={() => setIsAdditionalInfo(!isAdditionalInfo)}>{isAdditionalInfo ? 'Read Less' : 'Read More'}</button>
                    <div className="dataScience__readMoreArrow">
                        <div className={` ${isAdditionalInfo ? 'ti-angle-up' : 'ti-angle-down'} `}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DataScience;