import React, { useState } from "react"
import window from "global"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Testimonials from "../components/testimonials"
import WebDesignChennai from "../components/web-design-chennai"
import ItemsCarousel from "react-items-carousel"
import { Jumbotron, Accordion, Card, Button } from "react-bootstrap"
import PreviewCertificateModal from "../components/PreviewCertificateModal"
import CourseForm from "../components/CourseForm"
import YoutubeModal from "../components/YoutubeModal"
import DataScience from "../components/data-science"

export const query = graphql`
  query($slug: String!) {
    coursePageDataset(SlugURL: { eq: $slug }) {
      id
      Type
      CourseContent {
        Modules
        Name
        Description
      }
      Projects {
        Name
        Description
      }
      FAQ {
        A
        Q
        Number
      }
      CourseHighlights {
        icon
        title
      }
      CourseFeatures {
        param
        value
      }
      FeaturedYoutubeId
      Id
      LearningObjectives
      SlugURL
      Title
      BannerDescription
      LongDescription
      YoutubeThumbnailURL
      Tools {
        ImageUrl
      }
      SEO {
        param
        value
      }
      CertificateURL
      BrochureURL
    }
  }
`

const Course = ({ data }) => {
  const course = data.coursePageDataset

  const queryString = global.window?.location.search
  const urlParams = new URLSearchParams(queryString)
  const [utmParams] = useState({
    utmSource: urlParams.get("utm_source") || "",
  })
  const title =
    (course.SEO && course.SEO.find(({ param }) => param === "Title")?.value) ||
    ""
  const description =
    (course.SEO &&
      course.SEO.find(({ param }) => param === "Description")?.value) ||
    ""
  const url =
    (course.SEO && course.SEO.find(({ param }) => param === "Url")?.value) || ""
  const image =
    (course.SEO && course.SEO.find(({ param }) => param === "Image")?.value) ||
    ""
  const keywords =
    (course.SEO &&
      course.SEO.find(({ param }) => param === "Keywords")?.value) ||
    ""
  const author =
    (course.SEO && course.SEO.find(({ param }) => param === "Author")?.value) ||
    ""

  const [meta, setMeta] = useState({
    title,
    description,
    image,
    keywords,
    url,
    author,
  })

  const [videoModal, setVideoModal] = useState(false)

  const handleVideoModal = () => {
    setVideoModal(!videoModal)
  }

  const [showCertificate, setShowCertificate] = useState({
    show: false,
    certificateURL: course.CertificateURL || null,
    brochureURL: course.BrochureURL || null,
  })

  const [downloadSyllabusModal, setDownloadSyllabusModal] = useState(false)
  const [courseModalShow, setCourseModalShow] = useState(false)

  const handleDownloadSyllabus = () => {
    setDownloadSyllabusModal(true)
  }

  const handleCourseModal = () => {
    setCourseModalShow(!courseModalShow)
  }

  const handleCertificate = () => {
    setShowCertificate(prevState => {
      return { ...prevState, show: true }
    })
  }

  function handleClick(e) {
    var el = document.getElementById("detail" + e.currentTarget.id)
    if (el.style.display === "block") {
      el.style.display = "none"
      document.getElementById(e.currentTarget.id).innerText = "View details"
    } else {
      el.style.display = "block"
      document.getElementById(e.currentTarget.id).innerText = "Hide details"
    }
  }


  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const sixth = first + 5;
  function getSaturdayOfCurrentWeek() {
    const saturday = new Date(today.setDate(sixth));
    return saturday;
  }
const sat = getSaturdayOfCurrentWeek().toDateString();

  const today1 = new Date();
  const first1 = today1.getDate() - today1.getDay() + 1;
  const sixth1 = first1 + 3;
  function getThursdayOfCurrentWeek() {
    const thursday = new Date(today1.setDate(sixth1));
    return thursday;
  }
const thur = getThursdayOfCurrentWeek().toDateString();

  const [activeItemIndex, setActiveItemIndex] = useState(0)

  const chevronWidth = 40
  var numberOfCards = 0

  let defaultWidth

  if (typeof window !== `undefined`) {
    defaultWidth = window.innerWidth
  } else {
    defaultWidth = 992
  }

  if (defaultWidth <= 570) {
    numberOfCards = 4
  } else if (defaultWidth <= 768) {
    numberOfCards = 4
  } else if (defaultWidth <= 992) {
    numberOfCards = 6
  } else {
    numberOfCards = 7
  }

  let toolsDescription
  let batchAndMode

  switch (course.SlugURL) {
    case "full-stack-web-development-online-course":
      toolsDescription = `In this course, you'll get familiar and master the following tools and programming languages. These tools will help you deliver the job as a Full Stack Web Developer in the best way possible.`
      batchAndMode = [
        {
          batch: `${sat}`,
          deadline: `${thur}`,
          batchTime: "Evening Batch (Singapore, India and Malaysia)",
          mode: "ONLINE LIVE",
        },
        {
          batch: `${sat}`,
          deadline: `${thur}`,
          batchTime: "Evening Batch (Singapore, India and Malaysia)",
          mode: "CLASSROOM",
        },
      ]
      break
    case "front-end-online-course":
      toolsDescription = `In this course, you'll get familiar and master the following tools and programming languages. These tools will help you deliver the job as a Front-end Web Developer in the best way possible.`
      batchAndMode = [
        {
          batch: `${sat}`,
          deadline: `${thur}`,
          mode: "ONLINE LIVE",
        },
        {
          batch: `${sat}`,
          deadline: `${thur}`,
          mode: "OFFLINE",
        },
      ]
      break
    case "back-end-online-course":
      toolsDescription = `In this course, you'll get familiar and master the following tools and programming languages. These tools will help you deliver the job as a Back-end Web developer in the best way possible.`
      batchAndMode = [
        {
          batch: `${sat}`,
          deadline: `${thur}`,
          mode: "ONLINE LIVE",
        },
      ]
      break
    case "data-science-online-course":
      toolsDescription = `In this course, you'll get familiar and master the following tools and programming languages. These tools will help you deliver the job as a Data scientist in the best way possible.`
      batchAndMode = [
        {
          batch: `${sat}`,
          deadline: `${thur}`,
          mode: "ONLINE LIVE",
        },
      ]
      break
    case "digital-marketing-offline-courses":
      toolsDescription = `In this course, you'll get familiar and master the following tools. These tools will help you deliver the job as a digital marketer in the best way possible.`
      batchAndMode = [
        {
          batch:`${sat}`,
          deadline: `${thur}`,
          mode: "ONLINE LIVE",
        },
      ]
      break
    case "cloud-computing-online-course":
      toolsDescription = `In this course, you'll get familiar and master the following tools. These tools will help you deliver the job in the best way possible.`
      batchAndMode = [
        {
          batch: `${sat}`,
          deadline:`${thur}`,
          mode: "ONLINE LIVE",
        },
        {
          batch: `${sat}`,
          deadline:`${thur}`,
          mode: "CLASSROOM",
        },
      ]
      break
    default:
      toolsDescription = `In this course, you'll get familiar and master the following tools. These tools will help you deliver the job in the best way possible.`
      batchAndMode = [
        {
          batch:`${sat}`,
          deadline: `${thur}`,
          mode: "ONLINE LIVE",
        },
        {
          batch: `${sat}`,
          deadline: `${thur}`,
          mode: "CLASSROOM",
        },
      ]
      break
  }

  const courseSchema = `{"@context":"https://schema.org","@type":"Course","name":"${title}","description":"${description}","provider":{"@type":"Organization","name":"Crampete","sameAs":"${url}"}}`

  const videoSchema = `{"@context":"https://schema.org","@type":"VideoObject","name":"${title}","description":"${description}","thumbnailUrl":["${
    course.YoutubeThumbnailURL
      ? course.YoutubeThumbnailURL
      : "https://i.ytimg.com/vi/Wlx_SsSp1i8/maxresdefault.jpg"
  }"],"uploadDate":"2021-02-02T11:46:47.599Z","duration":"PT0.2M","embedUrl":"https://www.youtube.com/embed/${
    course.FeaturedYoutubeId
  }"}`

  const educationSchema = `{"@context":"http://schema.org","@type":"EducationEvent","name":"${title}","description":"${description}","performer":"Crampete","organizer":{"name":"Crampete","url":"https://www.crampete.com/"},"image":"https://www.crampete.com/images/logo.png","eventAttendanceMode":"https://schema.org/MixedEventAttendanceMode","eventStatus":"https://schema.org/EventScheduled","location": {"@type": "VirtualLocation", "url": "${url}" },"offers":{"@type":"AggregateOffer","lowPrice":"5000.0000","highPrice":"35000.0000","url":"${url}","availability":"http://schema.org/InStock","validFrom":"2021-02-10","price":"25000.0000","priceCurrency":"INR"},"startDate":"2021-04-10","endDate":"2021-05-10","url":"${url}","duration":"1"}`

  const productSchema = `{"@context":"https://schema.org/","@type":"Product","name":"Crampete","image":"https://www.crampete.com/images/logo.png","description":"${description}","brand":"Crampete","mpn": "001","sku":"9384058989","offers":{"@type":"Offer","url":"${url}","priceCurrency":"INR","price":"25000","priceValidUntil":"2021-11-10","availability":"https://schema.org/InStock"},"aggregateRating":{"@type":"AggregateRating","ratingValue":"4.5","bestRating":"4.5","worstRating":"1","ratingCount":"6548","reviewCount":"6548"},"review":{"@type":"Review","name":"Surya Narayanan","reviewBody":"I am from a mechanical background and I was doubtful whether I could learning this tech skill. Crampete taught the course from the basics in an easy to learn manner. The trainers cleared all my doubts. I recommend this course for anyone with zero coding knowledge.","reviewRating":{"@type":"Rating","ratingValue":"5","bestRating":"5","worstRating":"1"},"datePublished":"2020-10-11","author":{"@type":"Person","name":"Crampete"}}}`

  return (
    <Layout
      title={meta.title}
      description={meta.description}
      image={meta.image}
      keywords={meta.keywords}
      url={meta.url}
      author={meta.author}
      courseSchema={courseSchema}
      educationSchema={educationSchema}
      videoSchema={videoSchema}
      productSchema={productSchema}
    >
      <Jumbotron className="mb-0 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>{course.Title}</h1>
              <div
                dangerouslySetInnerHTML={{ __html: course.BannerDescription }}
              />
              <p>
                This course, designed for both students with and without
                experience in {course.Title}, makes you a well-rounded
                professional.
              </p>
              <Button
                onClick={handleCourseModal}
                className="btn mb-4 btn-primary"
              >
                ENQUIRE NOW
              </Button>
            </div>
            <div className="col-lg-6 m-auto">
              {course.FeaturedYoutubeId && (
                <Card
                  className="cardShadow p-0 text-center"
                  style={{ maxWidth: "500px", margin: "auto" }}
                >
                  <img
                    onClick={handleVideoModal}
                    src="https://cdn1.iconfinder.com/data/icons/logotypes/32/youtube-512.png"
                    className="image-youtube-icon"
                    alt="youtube icon"
                  />
                  <img
                    onClick={handleVideoModal}
                    className="image-youtube"
                    src={
                      course.YoutubeThumbnailURL
                        ? course.YoutubeThumbnailURL
                        : "https://i.ytimg.com/vi/Wlx_SsSp1i8/maxresdefault.jpg"
                    }
                    alt="youtube video"
                  />
                </Card>
              )}
            </div>
          </div>
        </div>
      </Jumbotron>
      <section style={{ background: "#f9fafe", padding: "20px" }}>
        <div className="container main-title course-syllabus">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="mt0">Course Syllabus</h3>
            <Button onClick={handleDownloadSyllabus}>Download Brochure</Button>
          </div>
          <p>
            Modules are picked to give you a well-rounded ability in{" "}
            {course.Title} skills.
          </p>
        </div>
        <div className="container">
          <Card className="mt-4 border-0">
            <div className="row">
              {course.CourseContent &&
                course.CourseContent.map((modules, index) => (
                  <div key={index} className="col-xl-4 mb-4">
                    <Card className="cardShadow h-100">
                      <Card.Body>
                        <Card.Title>{modules.Name}</Card.Title>
                        <Card.Text className="card-description">
                          {modules.Description}
                        </Card.Text>
                        <a
                          href="javascript:void(0)"
                          className="pl-0"
                          id={modules.Name}
                          onClick={handleClick}
                        >
                          View detail
                        </a>
                      </Card.Body>
                      <ul
                        id={"detail" + modules.Name}
                        className="collapse pl-4"
                        style={{ listStyle: "none" }}
                      >
                        {modules.Modules.map((item, index) => (
                          <li key={index} className="p-l-0 p-t-4 p-b-4">
                            {item}
                          </li>
                        ))}
                      </ul>
                    </Card>
                  </div>
                ))}
            </div>
          </Card>

          <div>
            {course.Tools && (
              <div className="container tools-wrapper">
                <div className="main-title">
                  <h3 className="mt-4">
                    {course.SlugURL == "digital-marketing-offline-courses"
                      ? `Tools you will learn`
                      : "Programming Languages / Tools you will learn"}
                  </h3>
                  <p>{toolsDescription}</p>
                </div>
                <div className="mt-4 p-4">
                  <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={numberOfCards}
                    gutter={20}
                    leftChevron={
                      <button
                        style={{ background: "transparent", border: "none" }}
                      >
                        {"<"}
                      </button>
                    }
                    rightChevron={
                      <button
                        style={{ background: "transparent", border: "none" }}
                      >
                        {">"}
                      </button>
                    }
                    outsideChevron
                    chevronWidth={chevronWidth}
                  >
                    {course.Tools.map((item, index) => (
                      <div key={index}>
                        <img
                          className="carousel-img"
                          src={item.ImageUrl}
                          alt="programming tool"
                        />
                      </div>
                    ))}
                  </ItemsCarousel>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section style={{ background: "#fff", padding: "20px" }}>
        <div className="container main-title">
          <h3 className="mt0">Projects</h3>
          <p>
            Each project is designed to deepen and solidify your theoretical and
            conceptual understanding.
          </p>
        </div>
        <div className="container mt-4 mb-4">
          <div className="row">
            {course.Projects &&
              course.Projects.map((modules, index) => (
                <div key={index} className="col-xl-4 mb-4">
                  <Card className="cardShadow h-100">
                    <Card.Body>
                      <Card.Title>{modules.Name}</Card.Title>
                      <Card.Text className="card-description">
                        {modules.Description}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
          <div className="alert alert-primary" role="alert">
            Due to Covid 19, we have moved all our classroom programs (in
            Malaysia, India and Singapore ) to online.
          </div>
        </div>

        <div className="container main-title">
          <h3 className="mt-4">Delivery Methods</h3>
          <p>
            We offer convenient training methodologies to suit your individual
            learning styles and preferences.
          </p>
        </div>
        <div className="container mt-4">
          <div className="row">
            {(course.SlugURL === "cloud-computing-online-course" ||
              course.SlugURL === "full-stack-web-development-online-course" ||
              course.SlugURL === "front-end-online-course") && (
              <>
                <div className="col-xl-4 mb-4">
                  <Card className="cardShadow">
                    <Card.Img
                      variant="top"
                      className="img-course-types"
                      src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/cramclass.jpg"
                    />
                    <Card.Body>
                      <Card.Title>Online Live Class</Card.Title>
                      <Card.Text className="card-description">
                        Access your training from the comforts of your home,
                        college or any other location you prefer. Learn with a
                        motivated group of individuals.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="col-xl-4 mb-4">
                  <Card className="cardShadow">
                    <Card.Img
                      variant="top"
                      className="img-course-types"
                      src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/gurukul.jpg"
                    />
                    <Card.Body>
                      <Card.Title>Gurukul (classroom)</Card.Title>
                      <Card.Text className="card-description">
                        Crampete Gurukul (classroom) model is a full-time
                        classroom-cum-internship program where you learn in a
                        high-stakes development environment.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </>
            )}
            {(course.SlugURL === "digital-marketing-offline-courses" ||
              course.SlugURL === "data-science-online-course" ||
              course.SlugURL === "back-end-online-course") && (
              <>
                <div className="col-xl-4 mb-4">
                  <Card className="cardShadow">
                    <Card.Img
                      variant="top"
                      className="img-course-types"
                      src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/cramclass.jpg"
                    />
                    <Card.Body>
                      <Card.Title>Online Live Class</Card.Title>
                      <Card.Text className="card-description">
                        Access your training from the comforts of your home,
                        college or any other location you prefer. Learn with a
                        motivated group of individuals.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </>
            )}
            {/* <div className="col-xl-4 mb-4">
                  <Card className="cardShadow">
                    <Card.Img
                      variant="top"
                      className="img-course-types"
                      src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/cramcode.jpg"
                    />
                    <Card.Body>
                      <Card.Title>Blended Class</Card.Title>
                      <Card.Text className="card-description">
                        Master theory and programming concepts with our recorded
                        videos and come to the learning centre for the hands-on labs
                        and project sessions.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div> */}
          </div>
        </div>
      </section>

      <section
        className="course-cert"
        style={{ background: "#f9fafe", padding: "20px" }}
      >
        <div className="container main-title">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="mt0">Batch Details</h3>
            {course.CertificateURL && (
              <Button onClick={handleCertificate}>Preview Certificate</Button>
            )}
          </div>
          <p>
            Flexible options that suit your learning temperament, to take where
            you want to be.
          </p>
          <div className="courseBatchWrapper">
            {batchAndMode.length > 0 &&
              batchAndMode.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="courseBatchContainer mb-4">
                      <Card className="cardShadow">
                        <Card.Body>
                          <Card.Title>{item.mode}</Card.Title>
                          <Card.Text className="card-description">
                            {item.batch}
                            <br />
                            {item.batchTime}
                            <br />
                            <br />
                            <span className="text-danger">{`Admission Deadline: ${item.deadline}`}</span>
                            <br />
                            <br />
                            <a
                              href="javascript:void(0)"
                              onClick={handleCourseModal}
                            >
                              Enrol Now
                            </a>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </React.Fragment>
                )
              })}
          </div>
          <div className="alert alert-primary" role="alert">
            Due to Covid 19, we have moved all our classroom programs (in
            Malaysia, India and Singapore ) to online.
          </div>
        </div>
      </section>
      {course.Type === "Cram Class Online" && (
        <section style={{ background: "#fff", padding: "20px" }}>
          <div className="container">
            <div className="main-title py-5">
              <h3 className="mt0">A community in the making!</h3>
              <p>
                Here's what our students have to say about being a part of the
                Crampete family and learning skills that empower them! A
                community in the making- sharing skills, experience and
                knowledge!
              </p>
            </div>
          </div>
          <Testimonials />
        </section>
      )}
      {course.SlugURL === "web-design-training-in-chennai" ? (
        <WebDesignChennai handleCourseModal={handleCourseModal} />
      ) : course.SlugURL === "data-science-course-in-chennai" ? (
        <DataScience handleCourseModal={handleCourseModal} />
      ) : (
        <section>
          <div dangerouslySetInnerHTML={{ __html: course.LongDescription }} />
        </section>
      )}
      {course.Type === "Cram Class Offline" && (
        <section style={{ background: "#fff", padding: "20px" }}>
          <div className="container">
            <div className="main-title py-5">
              <h3 className="mt0">A community in the making!</h3>
              <p>
                Here's what our students have to say about being a part of the
                Crampete family and learning skills that empower them! A
                community in the making- sharing skills, experience and
                knowledge!
              </p>
            </div>
          </div>
          <Testimonials />
        </section>
      )}
      <section>
        <div className="container">
          <Card className="border-0">
            <h3 style={{ marginBottom: "20px" }}>
              Frequently Asked Questions (FAQ's)
            </h3>
            <Accordion>
              <div itemScope itemType="https://schema.org/FAQPage">
                {course.FAQ &&
                  course.FAQ.map((modules, index) => (
                    <Card
                      key={index}
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                    >
                      <Card.Header
                        itemProp="name"
                        style={{
                          padding: ".2rem 0.5rem",
                          background: "rgb(248, 249, 250)",
                        }}
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={modules.Number}
                        >
                          <h2 style={{ fontSize: "1.1rem", margin: "0" }}>
                            {modules.Q}
                          </h2>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={modules.Number}>
                        <Card.Body
                          itemScope
                          itemProp="acceptedAnswer"
                          itemType="https://schema.org/Answer"
                        >
                          <p itemProp="text">{modules.A}</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
              </div>
            </Accordion>
          </Card>
        </div>
      </section>
      <hr className="container hrLight" />

      {courseModalShow && (
        <CourseForm
          utm={utmParams}
          course={course}
          setCourseModalShow={setCourseModalShow}
        />
      )}
      {downloadSyllabusModal && (
        <CourseForm
          brochureURL={showCertificate.brochureURL}
          course={course}
          downloadSyllabus={true}
          setDownloadSyllabusModal={setDownloadSyllabusModal}
          utm={utmParams}
        />
      )}
      {showCertificate.show && (
        <PreviewCertificateModal
          setShowCertificate={setShowCertificate}
          certificateURL={showCertificate.certificateURL}
        />
      )}
      {videoModal && (
        <YoutubeModal
          setVideoModal={setVideoModal}
          embedUrl={course.FeaturedYoutubeId}
        />
      )}
    </Layout>
  )
}

export default Course
